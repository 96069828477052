<template>
  <div>
    <h5>Addon categories</h5>
    <div v-if="false" class="filters">
      <b-form-group label="Search in categories" class="form-group">
        <b-form-input v-model="searchQuery" placeholder="search term here" />
      </b-form-group>
    </div>
    <b-table
      ref="catsListTable"
      sticky-header
      bordered
      hover
      responsive
      class="shadow-sm rounded"
      thead-tr-class="order-main-thead"
      :items="catsList"
      :fields="fields"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      small
    >
      <template #cell(actions)="data">
        <b-button
          variant="flat-primary"
          class="btn-icon"
          @click="editCatButton(data.item)"
        >
          <feather-icon size="16" icon="EditIcon" />
        </b-button>
        <b-button
          class="btn-icon"
          variant="flat-danger"
          @click="removeCatButton(data.item)"
        >
          <feather-icon size="16" icon="TrashIcon" />
        </b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
import { BTable, BFormInput, BFormGroup, BButton } from "bootstrap-vue";

export default {
  components: {
    BTable,
    BButton,
    BFormInput,
    BFormGroup,
  },
  props: {
    catsList: {
      type: Array,
      default: () => [],
    },
    editCatButton: {
      type: Function,
      default: () => {},
    },
    removeCatButton: {
      type: Function,
      default: () => {},
    },
    searchQuery: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      fields: [
        {
          label: "id",
          key: "id",
        },
        {
          label: "Category name",
          key: "nameGeo",
          sortable: false,
          formatter: (value, key, item) => `${item.nameGeo} / ${item.nameEng}`,
        },
        {
          label: "Actions",
          key: "Actions",
        },
      ],
    };
  },
  watch: {
    //
  },
  created() {
    //
  },
};
</script>

<style>
.filters .form-group {
  max-width: 300px;
}
</style>
