<!-- eslint-disable vue/html-self-closing -->
<template>
  <div>
    <b-modal id="main-image-modal" size="lg" ok-only>
      <img
        v-if="mainImageModalUrl"
        :src="mainImageModalUrl"
        alt="main image"
        class="modal-image mx-auto"
      />
    </b-modal>
    <h5>Addons</h5>
    <div v-if="false" class="filters">
      <b-form-group label="Search in Addons" class="form-group">
        <b-form-input v-model="searchQuery" placeholder="search term here" />
      </b-form-group>
    </div>
    <b-overlay :show="showLoading" rounded="sm">
      <b-table
        ref="refProductsListTable"
        sticky-header
        bordered
        hover
        responsive
        class="shadow-sm rounded"
        thead-tr-class="order-main-thead"
        :items="addonsList"
        :fields="fields"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
        small
      >
        <template #cell(imageUrl)="data">
          <img
            :src="data.item.imageUrl"
            class="thumb-image"
            @click="handleMainImageModal(data.item.imageUrl)"
          />
        </template>
        <template #cell(actions)="data">
          <b-button
            variant="flat-primary"
            class="btn-icon"
            @click="editAddonButton(data.item)"
          >
            <feather-icon size="16" icon="EditIcon" />
          </b-button>
          <b-button
            class="btn-icon"
            variant="flat-danger"
            @click="removeAddonButton(data.item)"
          >
            <feather-icon size="16" icon="TrashIcon" />
          </b-button>
        </template>
      </b-table>
    </b-overlay>
  </div>
</template>

<script>
import {
  BTable,
  BFormInput,
  BFormGroup,
  BButton,
  BOverlay,
  BModal,
} from "bootstrap-vue";

export default {
  components: {
    BTable,
    BButton,
    BFormInput,
    BFormGroup,
    BOverlay,
    BModal,
  },
  props: {
    addonsList: {
      type: Array,
      default: () => [],
    },
    editAddonButton: {
      type: Function,
      default: () => {},
    },
    removeAddonButton: {
      type: Function,
      default: () => {},
    },
    searchQuery: {
      type: String,
      default: "",
    },
    showLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mainImageModalUrl: null,
      fields: [
        {
          label: "Addon name",
          key: "nameGeo",
          sortable: false,
          formatter: (value, key, item) => `${item.nameGeo} / ${item.nameEng}`,
        },
        {
          label: "Category",
          key: "category",
          sortable: false,
          formatter: (value, key, item) =>
            item.category
              ? `${item.category?.nameGeo} / ${item.category?.nameEng} `
              : "",
        },
        {
          key: "price",
          label: "Price",
          sortable: false,
          formatter: (value, key, item) => `${item.price.toFixed(2)} ₾`,
        },
        {
          key: "count",
          label: "Count",
          sortable: false,
        },
        {
          label: "Type",
          key: "type",
          formatter: (value) => `${this.type[value]}`,
          sortable: false,
        },
        {
          label: "Image",
          key: "imageUrl",
        },
        {
          label: "actions",
          key: "actions",
        },
      ],
      type: {
        1: "Background Letter",
        2: "DigitalWrapping",
      },
    };
  },
  watch: {
    //
  },
  methods: {
    handleMainImageModal(imageUrl) {
      this.mainImageModalUrl = imageUrl;
      this.$bvModal.show("main-image-modal");
    },
  },
};
</script>

<style lang="scss" scoped>
.filters .form-group {
  max-width: 300px;
}
.thumb-image {
  width: auto;
  max-height: 35px;
  border-radius: 3px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}
.modal-image {
  margin: 0 auto;
  max-width: 100%;
  object-fit: cover;
  display: block;
}
</style>
