<template>
  <div>
    <b-button
      v-b-toggle.create-addon-collapse
      pill
      size="sm"
      variant="outline-primary"
      class="mb-1 ml-auto d-block"
    >
      {{ $i18n.t("addons.addNew") }}
    </b-button>
    <b-collapse
      id="create-addon-collapse"
      class="border mb-1 rounded shadow-sm"
    >
      <b-overlay :show="showLoading" rounded="sm" class="p-1">
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">{{ $i18n.t("addons.basicInformation") }}</h5>
          </b-col>
          <b-col md="3">
            <b-form-group
              :label="$i18n.t('addons.nameGeo')"
              label-for="i-name-geo"
            >
              <b-form-input id="i-geo" v-model="nameGeo" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              :label="$i18n.t('addons.nameEng')"
              label-for="i-name-eng"
            >
              <b-form-input id="i-eng" v-model="nameEng" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              :label="$i18n.t('addons.count')"
              label-for="i-name-eng"
            >
              <b-form-input id="i-eng" v-model="count" type="number" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              :label="$i18n.t('addons.price')"
              label-for="i-name-eng"
            >
              <b-form-input
                id="i-eng"
                v-model="price"
                :readonly="isFree"
                type="number"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3">
            <b-form-group
              :label="$i18n.t('addons.addonType')"
              label-for="i-name-eng"
            >
              <b-form-select
                v-model="type"
                :options="typeOptions"
                text-field="label"
                value-field="id"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              :label="$i18n.t('addons.addonCategory')"
              label-for="i-name-eng"
            >
              <b-form-select
                v-model="addOnCategoryId"
                :options="addonCategories"
                :text-field="$i18n.locale === 'ka' ? 'nameGeo' : 'nameEng'"
                value-field="id"
                placeholder="select"
              >
                <b-form-select-option :value="null">
                  {{ $i18n.t("addons.selectCategory") }}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              :label="$i18n.t('addons.mainImage')"
              label-for="i-name-eng"
            >
              <b-form-file
                v-model="image"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              />
            </b-form-group>
          </b-col>
          <b-col md="1" class="d-flex align-items-center">
            <b-form-checkbox
              v-model="isFree"
              checked="true"
              name="check-button"
              inline
            >
              {{ $i18n.t("addons.isFree") }}
            </b-form-checkbox>
          </b-col>
          <b-col md="2" class="d-flex align-items-center">
            <b-button
              pill
              :disabled="showLoading"
              variant="outline-success"
              @click="handleAddAddon"
            >
              {{ $i18n.t("addons.submit") }}
            </b-button>
          </b-col>
        </b-row>
      </b-overlay>
    </b-collapse>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BFormFile,
  BFormCheckbox,
  BCollapse,
  BButton,
  VBToggle,
  BOverlay,
} from "bootstrap-vue";

export default {
  components: {
    BFormInput,
    BFormGroup,
    BCol,
    BFormSelect,
    BFormSelectOption,
    BFormFile,
    BFormCheckbox,
    BRow,
    BCollapse,
    BButton,
    BOverlay,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      addOnCategoryId: null,
      type: null,
      nameGeo: "",
      nameEng: "",
      count: 0,
      price: 0,
      isFree: false,
      image: null,
    };
  },
  computed: {
    ...mapState("addonsModule", ["showLoading", "addonCategories"]),
    typeOptions() {
      return [
        {
          label: this.$i18n.t("addons.selectType"),
          id: null,
        },
        {
          label: this.$i18n.t("addons.backgroundLetter"),
          id: 1,
        },
        {
          label: this.$i18n.t("addons.digitalWrapping"),
          id: 2,
        },
      ];
    },
  },
  created() {
    this.getAddonCategories({
      skip: 0,
      take: 10,
      searchKey: "",
    });
  },
  methods: {
    ...mapActions("addonsModule", [
      "getAddonCategories",
      "createAddon",
      "getAddons",
    ]),
    handleImageUploadChange(e) {
      console.log(e);
    },
    toast(message = "success", type = "success") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: "BellIcon",
          variant: type,
        },
      });
    },
    clearForm() {
      this.addOnCategoryId = null;
      this.type = null;
      this.nameGeo = "";
      this.nameEng = "";
      this.count = 0;
      this.price = 0;
      this.isFree = false;
      this.image = null;
    },
    async handleAddAddon() {
      const formDataObject = {
        addOnCategoryId: this.addOnCategoryId,
        type: this.type,
        nameGeo: this.nameGeo,
        nameEng: this.nameEng,
        count: this.count,
        price: this.price,
        isFree: this.isFree,
        image: this.image,
      };
      const formData = new FormData();

      Object.keys(formDataObject).forEach((e) => {
        formData.append(e, formDataObject[e]);
      });

      try {
        await this.createAddon(formData);
        this.clearForm();
        this.getAddons({ skip: 0, take: 10, searchKey: "" });
        this.toast("Addon created successfully", "success");
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style></style>
