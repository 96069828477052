<template>
  <div>
    <b-row>
      <b-col cols="12">
        <h6 class="mb-0">{{ $i18n.t("addons.addNewCategory") }}</h6>
      </b-col>
      <b-col md="3">
        <b-form-group :label="$i18n.t('addons.nameGeo')" label-for="i-name-geo">
          <b-form-input id="i-geo" v-model="nameGeo" size="sm" />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group :label="$i18n.t('addons.nameEng')" label-for="i-name-eng">
          <b-form-input id="i-eng" v-model="nameEng" size="sm" />
        </b-form-group>
      </b-col>
      <b-col md="2" class="d-flex align-items-center">
        <b-button
          pill
          :disabled="showLoading"
          variant="outline-success"
          size="sm"
          @click="handleAddAddonCategory"
        >
          {{ isEditState ? $i18n.t("global.edit") : $i18n.t("global.submit") }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  VBToggle,
} from "bootstrap-vue";

export default {
  components: {
    BFormInput,
    BFormGroup,
    BCol,
    BRow,
    BButton,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    categoryToEdit: {
      type: Object,
      default: () => {},
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      nameGeo: "",
      nameEng: "",
    };
  },
  computed: {
    ...mapState("addonsModule", ["showLoading", "addonCategories"]),
    isEditState: {
      get() {
        return this.isEdit;
      },
      set(val) {
        this.$emit("update:isEdit", val);
      },
    },
  },
  watch: {
    categoryToEdit(newVal) {
      if (this.isEditState) {
        this.nameGeo = newVal.nameGeo;
        this.nameEng = newVal.nameEng;
      }
    },
  },
  methods: {
    ...mapActions("addonsModule", [
      "getAddonCategories",
      "createAddonCategory",
      "editAddonCategory",
    ]),
    toast(message = "success", type = "success") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: "BellIcon",
          variant: type,
        },
      });
    },
    clearForm() {
      this.nameGeo = "";
      this.nameEng = "";
    },
    async handleAddAddonCategory() {
      const payload = {
        nameGeo: this.nameGeo,
        nameEng: this.nameEng,
      };

      if (this.isEditState) {
        payload.id = this.categoryToEdit.id;
        try {
          await this.editAddonCategory(payload);
          this.clearForm();
          this.getAddonCategories({
            skip: 0,
            take: 10,
            searchKey: "",
          });
          this.toast("Category edited successfully");
          this.isEditState = false;
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          await this.createAddonCategory(payload);
          this.clearForm();
          this.getAddonCategories({
            skip: 0,
            take: 10,
            searchKey: "",
          });
          this.toast("Category added successfully");
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
};
</script>

<style></style>
