<template>
  <b-card
    :header="$i18n.t('navigation.addons')"
    header-bg-variant="primary"
    header-text-variant="white"
  >
    <CreateAddonForm />
    <b-modal ref="addon-edit-modal" ok-only ok-title="Close" size="xl" centered>
      <EditAddonForm :addon-to-edit="addonToEdit" :close-modal="closeModal" />
    </b-modal>
    <div class="my-4">
      <AddonTable
        :addons-list="addonsList"
        :edit-addon-button="editAddonButton"
        :remove-addon-button="removeAddonButton"
        :search-query="searchQuery"
        :show-loading="showLoading"
      />
      <Pagination
        :fetch-data="fetchAddons"
        :skip.sync="skip"
        :total-rows="totalRows"
        :take.sync="take"
      />
    </div>
    <CreateAddonCategoryFrom
      :category-to-edit="categoryToEdit"
      :is-edit.sync="isEdit"
    />
    <CatsTable
      :cats-list="addonCategories"
      :edit-cat-button="editCatButton"
      :remove-cat-button="removeCatButton"
      :cat-search-query="catSearchQuery"
    />
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import Swal from "sweetalert2";
import Pagination from "@/views/components/Pagination/Pagination.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AddonTable from "./Components/AddonTable.vue";
import CatsTable from "./Components/CatsTable.vue";
import CreateAddonForm from "./Components/CreateAddonForm.vue";
import EditAddonForm from "./Components/EditAddonForm.vue";
import CreateAddonCategoryFrom from "./Components/CreateAddonCategoryForm.vue";

export default {
  components: {
    BCard,
    AddonTable,
    CatsTable,
    CreateAddonForm,
    EditAddonForm,
    Pagination,
    CreateAddonCategoryFrom,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      isEdit: false,
      totalRows: 0,
      take: 10,
      skip: 0,
      categoryToEdit: null,
      addonsList: [],
      catsList: [],
      searchQuery: "",
      catSearchQuery: "",
      addonToEdit: null,
    };
  },

  computed: {
    ...mapState("addonsModule", [
      "addons",
      "addonCategories",
      "singleAddon",
      "showLoading",
    ]),
  },
  watch: {
    addons(newVal) {
      this.addonsList = newVal.addOns;
      this.totalRows = newVal.count;
    },
    take() {
      this.fetchAddons();
    },
    addonCategories(newVal) {
      this.catsList = newVal;
    },
  },
  created() {
    this.fetchAddons();
    this.getAddonCategories({
      skip: 0,
      take: 10,
      searchKey: "",
    });
  },
  methods: {
    ...mapActions("addonsModule", [
      "getAddons",
      "getAddonById",
      "updateAddonById",
      "deleteAddonById",
      "getAddonCategories",
      "deleteAddonCategory",
    ]),
    toast(message = "Succes") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: "FileIcon",
          variant: "success",
        },
      });
    },
    closeModal() {
      this.$refs["addon-edit-modal"].hide();
      this.addonToEdit = null;
    },
    editAddonButton(prod) {
      this.addonToEdit = prod;
      this.$refs["addon-edit-modal"].show();
    },
    async removeAddonButton(prod) {
      const result = await this.swalConfirmation(`addon - ${prod.nameGeo} / ${prod.nameEng}`);
      if (result.isConfirmed) {
        try {
          await this.deleteAddonById(prod.id);
          this.fetchAddons();
          this.toast("Addon deleted successfully");
        } catch (err) {
          console.log(err);
        }
      }
    },
    fetchAddons() {
      this.getAddons({
        skip: this.skip,
        take: this.take,
      });
    },
    editCatButton(cat) {
      this.isEdit = true;
      this.categoryToEdit = cat;
    },
    swalConfirmation(item) {
      return Swal.fire({
        title: `Are you sure you want to delete ${item}?`,
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete!",
      });
    },
    async removeCatButton(prod) {
      const result = await this.swalConfirmation(`category - ${prod.nameGeo} / ${prod.nameEng}`);
      if (result.isConfirmed) {
        try {
          await this.deleteAddonCategory(prod.id);
          this.getAddonCategories();
          this.toast("Addon category deleted successfully");
        } catch (err) {
          console.log(err);
        }
      }
    },
  },
};
</script>

<style>
.filters .form-group {
  max-width: 300px;
}
</style>
